import WeeklyGoalsType from "../@types/services/WeeklyGoalsType";
import api from "./api";

export const fetchWeeklyGoalsGroups = async () => {
  const response = await api.get("/weekly_goals_groups", {
    baseURL: "/vbuyer/api/v2",
  });
  return response.data;
};

export const fetchUserWeeklyGoals = async (userId: string) => {
  const response = await api.get(`/weekly_goals`, {
    baseURL: "/vbuyer/api/v2",
    params: {
      user: userId,
    },
  });
  return response.data;
};

export const createWeeklyGoal = async (weeklyGoal: WeeklyGoalsType) => {
  const response = await api.post(
    `/weekly_goals`,
    {
      weekly_goal: weeklyGoal,
    },
    {
      baseURL: "/vbuyer/api/v2",
    },
  );
  return response.data;
};

export const updateWeeklyGoal = async (weeklyGoal: WeeklyGoalsType) => {
  const response = await api.put(
    `/weekly_goals/${weeklyGoal.id}`,
    {
      weekly_goal: weeklyGoal,
    },
    {
      baseURL: "/vbuyer/api/v2",
    },
  );
  return response.data;
};

export const deleteWeeklyGoal = async (id: number) => {
  const response = await api.delete(`/weekly_goals/${id}`, {
    baseURL: "/vbuyer/api/v2",
  });
  return response.data;
};
