import {
  mdiContentSaveOutline,
  mdiDeleteOutline,
  mdiPlus,
  mdiUndo,
} from "@mdi/js";
import Icon from "@mdi/react";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  FormControl,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { enqueueSnackbar } from "notistack";
import {
  Dispatch,
  FC,
  ReactElement,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import {
  FieldArrayWithId,
  SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";
import WeeklyGoalsGroupType from "../../../@types/services/WeeklyGoalsGroupType";
import WeeklyGoalsType from "../../../@types/services/WeeklyGoalsType";
import {
  createWeeklyGoal,
  deleteWeeklyGoal,
  fetchUserWeeklyGoals,
  fetchWeeklyGoalsGroups,
  updateWeeklyGoal,
} from "../../../services/weeklyGoals";

type UserWeeklyGoalsProps = {
  userId: string;
};

type FormValues = {
  weeklyGoals: WeeklyGoalsType[];
};

type FormWeeklyGoalProps = {
  userId: number;
  weeklyGoals: WeeklyGoalsType[];
  weeklyGoalsGroups: WeeklyGoalsGroupType[];
  setWeeklyGoals: Dispatch<SetStateAction<WeeklyGoalsType[]>>;
};

const FormWeeklyGoal: FC<FormWeeklyGoalProps> = ({
  userId,
  weeklyGoals,
  weeklyGoalsGroups,
  setWeeklyGoals,
}) => {
  const { handleSubmit, control, register, setValue, watch, reset } =
    useForm<FormValues>({
      defaultValues: {
        weeklyGoals: weeklyGoals,
      },
    });
  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray(
    {
      control,
      name: "weeklyGoals",
    },
  );
  const watchWeeklyGoals = watch("weeklyGoals");

  const handleRemove = (
    field: FieldArrayWithId<FormValues, "weeklyGoals", "id">,
    index: number,
  ) => {
    if (watchWeeklyGoals[index].id === null) {
      remove(index);
    } else {
      setValue(
        `weeklyGoals.${index}._destroy`,
        !watchWeeklyGoals[index]._destroy,
      );
    }
  };

  useEffect(() => {
    console.log(weeklyGoals);
    reset({ weeklyGoals: weeklyGoals });
  }, [weeklyGoals]);

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    if (data.weeklyGoals?.length === weeklyGoals?.length) {
      const sortedDataGoals = data.weeklyGoals.sort(
        (a, b) => (a.id ?? Infinity) - (b.id ?? Infinity),
      );
      const sortedWeeklyGoals = weeklyGoals.sort(
        (a, b) => (a.id ?? Infinity) - (b.id ?? Infinity),
      );
      if (
        JSON.stringify(sortedDataGoals) === JSON.stringify(sortedWeeklyGoals)
      ) {
        enqueueSnackbar("Nenhuma alteração foi feita");
        return;
      }
    }
    data.weeklyGoals.forEach((goal) => {
      if (goal.weekly_goals_group_id === 0) {
        enqueueSnackbar("Todas as metas devem ter grupos de metas");
      }
      if (goal.id) {
        // verifica se tem id, se não tiver, cria nova meta
        if (goal._destroy) {
          deleteWeeklyGoal(goal.id).then(() => {
            enqueueSnackbar("Meta semanal excluída com sucesso");
            setWeeklyGoals((prevGoals) =>
              prevGoals.filter((g) => g.id !== goal.id),
            );
          });
        } else {
          if (
            JSON.stringify(goal) !==
            JSON.stringify(weeklyGoals.find((g) => g.id === goal.id))
          )
            updateWeeklyGoal(goal).then((response) => {
              enqueueSnackbar("Meta semanal atualizada com sucesso");
              const updatedGoal = { ...response.result, _destroy: false };
              setWeeklyGoals((prevGoals) =>
                prevGoals.map((g) => (g.id === goal.id ? updatedGoal : g)),
              );
            });
        }
      } else {
        createWeeklyGoal(goal).then((response) => {
          enqueueSnackbar("Meta semanal criada com sucesso");
          const createdGoal = { ...response.result, _destroy: false };
          setWeeklyGoals((prevGoals) => [...prevGoals, createdGoal]);
        });
      }
    });
  };

  return (
    <>
      <Button
        startIcon={<Icon path={mdiPlus} size={1} />}
        onClick={() => {
          append({
            id: null,
            user_id: userId,
            weekly_goals_group_id: 0,
            number_of_tasks: 0,
            _destroy: false,
          });
        }}
        variant="contained"
        sx={{ maxWidth: "250px" }}
      >
        {"Nova meta semanal"}
      </Button>
      <form
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "16px",
        }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "8px",
          }}
        >
          {fields.map((field, index) => (
            <Paper
              key={field.id}
              elevation={watchWeeklyGoals[index]._destroy ? 0 : 2}
              sx={{
                padding: "16px",
                width: "100%",
                display: "flex",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  gap: "8px",
                }}
              >
                <FormControl fullWidth>
                  <Autocomplete
                    {...register(`weeklyGoals.${index}.weekly_goals_group_id`)}
                    options={weeklyGoalsGroups}
                    getOptionLabel={(option) =>
                      `Grupo ${option.id} - (${option.group_reasons.join(" | ")})`
                    }
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Grupo de metas semanais"
                        fullWidth
                        sx={{
                          opacity: watchWeeklyGoals[index]._destroy ? 0.5 : 1,
                        }}
                        disabled={watchWeeklyGoals[index]._destroy}
                      />
                    )}
                    onChange={(event, newValue) => {
                      setValue(
                        `weeklyGoals.${index}.weekly_goals_group_id`,
                        newValue?.id || 0,
                      );
                    }}
                    defaultValue={
                      weeklyGoalsGroups.find(
                        (group) => group.id === field.weekly_goals_group_id,
                      ) || null
                    }
                  />
                </FormControl>
                <FormControl>
                  <TextField
                    {...register(`weeklyGoals.${index}.number_of_tasks`)}
                    type="number"
                    label="Número de tarefas"
                    id={`outlined-start-adornment-${index}`}
                    sx={{
                      opacity: watchWeeklyGoals[index]._destroy ? 0.5 : 1,
                    }}
                    disabled={watchWeeklyGoals[index]._destroy}
                  />
                </FormControl>
              </Box>
              <Box
                sx={{
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {watchWeeklyGoals[index]._destroy ? (
                  <Button
                    startIcon={<Icon path={mdiUndo} size={1} />}
                    onClick={() => handleRemove(field, index)}
                  >
                    {"Desfazer"}
                  </Button>
                ) : (
                  <Button
                    variant="text"
                    color="error"
                    startIcon={<Icon path={mdiDeleteOutline} size={1} />}
                    onClick={() => handleRemove(field, index)}
                  >
                    {"Excluir"}
                  </Button>
                )}
              </Box>
            </Paper>
          ))}
        </Box>
        <Button
          type="submit"
          variant="contained"
          color="success"
          sx={{ paddingTop: "8px", maxWidth: "350px" }}
          startIcon={<Icon path={mdiContentSaveOutline} size={1} />}
        >
          {"Salvar"}
        </Button>
      </form>
    </>
  );
};

const UserWeeklyGoals: FC<UserWeeklyGoalsProps> = ({
  userId,
}): ReactElement => {
  const [weeklyGoalsGroups, setWeeklyGoalsGroups] = useState<
    WeeklyGoalsGroupType[]
  >([]);

  const [weeklyGoals, setWeeklyGoals] = useState<WeeklyGoalsType[]>();

  const handleFetchWeeklyGoalsGroups = useCallback(async () => {
    await fetchWeeklyGoalsGroups().then((response) => {
      setWeeklyGoalsGroups(response.results);
    });
  }, []);

  const handleFetchUserWeeklyGoals = useCallback(async () => {
    await fetchUserWeeklyGoals(userId).then((response) => {
      if (response.results) {
        const updatedResults = response.results
          .map((goal: WeeklyGoalsType) => ({
            ...goal,
            _destroy: false,
          }))
          .sort(
            (a: WeeklyGoalsType, b: WeeklyGoalsType) =>
              (a.id ?? Infinity) - (b.id ?? Infinity),
          );

        setWeeklyGoals(updatedResults);
      } else {
        setWeeklyGoals([]);
      }
    });
  }, []);

  useEffect(() => {
    handleFetchWeeklyGoalsGroups();
    handleFetchUserWeeklyGoals();
  }, []);

  return (
    <>
      {weeklyGoals && weeklyGoalsGroups ? (
        <Paper
          sx={{
            padding: "24px",
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            flexShrink: 0,
            flexGrow: 1,
          }}
        >
          <Typography variant="h6" component="div">
            {"Metas Semanais"}
          </Typography>
          <FormWeeklyGoal
            weeklyGoals={weeklyGoals}
            weeklyGoalsGroups={weeklyGoalsGroups}
            userId={Number(userId)}
            setWeeklyGoals={setWeeklyGoals}
          />
        </Paper>
      ) : (
        <CircularProgress />
      )}
    </>
  );
};

export default UserWeeklyGoals;
